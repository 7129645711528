import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Checkbox, Drawer, FormControlLabel } from '@mui/material';
import { CheckBox, PrintRounded } from '@mui/icons-material';
import PreparationInputDialog from './PreparationInputDialog';
import { useState } from 'react';
import PreparationRecipeView from './PreparationRecipeView';
import PreparationRecipeDownload from './PreparationRecipeDownload';

export default function PreparationCard({ recipe, setAdditionalRecipes, itemProperties, setItemProperties, setSelectedItem, setSelectedRecipe, fetchPreperationRecipes, recipeRef }) {
    const [showInput, setShowInput] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [recipeData, setRecipeData] = useState({})
    const cardStyle = {
        boxShadow: 'none',
        maxWidth: 345,
        minWidth: 345,
        borderRadius: "15px"
    };
    const cardStyleMain = {
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        maxWidth: 345,
        minWidth: 345,
        borderRadius: "15px"
    };
    const handleClick = () => {
        if (recipe.item) {
            setSelectedItem({ ...recipe.item })
        }
        if (recipe.additionalIngredients) {
            const additionalRecipeToUpdate = recipe.additionalIngredients.map(item => {
                return { ...item, _id: recipe._id }
            })
            setAdditionalRecipes([...additionalRecipeToUpdate])
        }
    }
    const handleSelectionChange = (e, property) => {
        if (e.target.checked) {
            const optionArr = new Set([...(itemProperties[property] ?? [])])
            optionArr.add({ name: e.target.value, price: 0 })
            setItemProperties({
                ...itemProperties,
                [property]: [...optionArr]
            })
        } else {
            const optionArr = (itemProperties[property] ?? []).filter(op => op.name !== e.target.value)
            setItemProperties({
                ...itemProperties,
                [property]: [...optionArr]
            })

        }
    }

    return (
        <div style={cardStyleMain}>

            <Card sx={cardStyle}  >
                <CardActionArea>
                    <CardMedia
                        onClick={() => {
                            if (recipe.ingredients[0]) {
                                setRecipeData(recipe)
                                setOpenDrawer(true)
                            }
                        }}
                        component="img"
                        height="140"
                        image={recipe?.item?.image ?? "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8fA%3D%3D"}
                        alt="green iguana"
                    />
                </CardActionArea>
            </Card>

            <CardContent>
                <Typography variant="div" display="flex" width={"100%"} gap="20px" alignItems={"center"} justifyContent={"space-between"} marginTop={"0px"}>
                    {recipe?.item?.name ?
                        <b onClick={handleClick} style={{ cursor: "pointer" }}>

                            {recipe?.item?.name}
                        </b>
                        :
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={itemProperties?.[recipe.property]?.find(op => op.name === recipe.option)} onChange={(e) => handleSelectionChange(e, recipe.property)} value={recipe.option} />
                            } label={<b>{recipe.option}</b>} />}
                    {
                        recipe.ingredients[0] ?
                            <Button onClick={(e) => {
                                e.stopPropagation()
                                setShowInput(true)
                            }}>
                                <PrintRounded />
                            </Button>
                            :
                            <Button sx={{ visibility: "hidden" }}>
                                <PrintRounded />
                            </Button>
                    }

                </Typography>
            </CardContent>
            <PreparationInputDialog
                setSelectedRecipe={setSelectedRecipe}
                recipeRef={recipeRef}
                open={showInput}
                setOpen={setShowInput}
                recipe={recipe}
            />
            <Drawer
                open={openDrawer}
                anchor='right'
                onClose={() => { setOpenDrawer(false) }}
            >
                <PreparationRecipeView recipe={recipeData}></PreparationRecipeView>

            </Drawer>
        </div>

    );
}
