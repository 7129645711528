import { Button, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import axios from 'axios';
import toast from '../../utils/toast';
import { mySocket } from '../../components/SocketWrapper/SocketWrapper';
import { useNavigate } from 'react-router-dom';
import PreparationOrderView from './PreparationOrderView';
import { PrintSharp, RemoveRedEyeSharp } from '@mui/icons-material';

export default function PreparationOrders() {
    const [orders, setOrders] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)
    
    const [recipeData, setRecipeData] = useState({})

    const fetchData = async () => {
        try {
            const res = await axios.get(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/recipe/preparationRecipe/orders`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            setOrders([...res.data])
        } catch (err) {
            if (err.isAxiosError && err.code === 'ERR_NETWORK') {
                toast.error("Network connection failed", {
                    autoClose: 2000
                })
            } else {
                  toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                      autoClose: 2000
                  })
            }
        }
    }
    const handlePrint = (recipe, quantity, item) => {
        if (mySocket) {
            mySocket.emit("on-kitchen-recipe-print", {
                room: "cashier" + localStorage.getItem("restaurant"),
                recipe,
                quantity,
                item
            })
            toast.success("Recipe added to print...")
        } else {
            toast.error("Printer is not connected")
        }

    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <Header title="Preparation Orders" />
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow
                            sx={{
                                backgroundColor: "#FEF2E2",
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

                                "& .MuiTableCell-root": {
                                    fontWeight: "bold",
                                    color: "#9E5E28",
                                    backgroundColor: "#FEF2E2",
                                },
                            }}
                        >
                            <TableCell>Order ID</TableCell>
                            <TableCell>Recipe name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(orders ?? []).map((order) => {

                            return (
                                <TableRow>
                                    <TableCell>{order?._id}</TableCell>

                                    <TableCell>{order?.item?.name} {(order?.recipe?.option ? " ("+order?.recipe.option+") " : null )}</TableCell>
                                    <TableCell>{order?.quantity}</TableCell>
                                    <TableCell>{order?.recipe?.unit}</TableCell>
                                    <TableCell>{order?.createdAt ? new Date(order?.createdAt).toLocaleString() : "--"}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => {
                                                setRecipeData({recipe:order?.recipe, quantity:order?.quantity, itemName:order?.item?.name})
                                                setOpenDrawer(true)
                                            }}
                                        ><RemoveRedEyeSharp/></Button>
                                        <Button
                                            color="error"
                                            onClick={() => handlePrint(order.recipe, order.quantity, order.item)}
                                        ><PrintSharp/></Button>
                                    </TableCell>

                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Drawer
                open={openDrawer}
                anchor='right'
                onClose={() => {setOpenDrawer(false) }}
            >
                <PreparationOrderView recipeData={recipeData}></PreparationOrderView>
            </Drawer>
        </div>
    )
}
