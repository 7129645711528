import axios from "axios";
import React, { useState } from "react";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";
import { Dialog, Typography } from "@mui/material";
import GradientBorder from "../../GradientBorder/GradientBorder";
import styles from "./UnprepareDialog.module.css";
import vibrator from "../../../utils/vibrator";
import commonStyles from "../../../styles.module.css";
import { CheckBox } from "@mui/icons-material";

const UnprepareDialog = ({ open, setOpen, handleUnprepare }) => {
	const [deletionReason, setDeletionReason] = useState("");
	const [password, setPassword] = useState("");
	const [wasted, setWasted] = useState(false);

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Confirm Unprepare
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Password
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="password"
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Reason
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								value={deletionReason}
								onChange={(e) => {
									setDeletionReason(e.target.value);
								}}
							/>
						</GradientBorder>
					</div>
					<div style={{display:"flex", marginTop:"10px"}}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Is the food wasted
						</Typography>
						
							<input
								type="checkbox"
								checked={wasted}
								onChange={(e) => {
									setWasted(e.target.checked)
								}}
							/>
					</div>
					<div className={styles.dialog_button_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								setOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={() => {
								vibrator.tap();
								if (password === "st24632") {
									if (deletionReason === "") {
										toast.error("Please enter a reason", {
											autoClose: 2000,
										});
										return;
									}
									handleUnprepare(deletionReason, wasted);
								} else {
									toast.error("Incorrect Password", {
										autoClose: 2000,
									});
								}
							}}
						>
							Confirm
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default UnprepareDialog;
