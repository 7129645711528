import React, { useContext, useState } from "react";
import styles from "./Drawer.module.css";
import MuiDrawer from "@mui/material/Drawer";
import logo from "../../assets/images/shakespeare_logo.png";
import GradientText from "../GradientText/GradientText";
import { List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { orders } from "../../providers/OrderProvider";
import { restaurant } from "../../providers/RestaurantProvider";
import { cart } from "../../providers/CartProvider";
import vibrator from "../../utils/vibrator";
import commonStyles from "../../styles.module.css";
import { MailRounded } from "@mui/icons-material";
import GradientBorder from "../GradientBorder/GradientBorder";
import whatsapp from "../../assets/icons/whatsapp.png";

const Drawer = ({ open, setIsDrawerOpen }) => {
	const navigate = useNavigate();
	const ordersContext = useContext(orders);
	const restaurantContext = useContext(restaurant);
	const cartContext = useContext(cart);
	return (
		<React.Fragment>
			<MuiDrawer
				variant="temporary"
				anchor="left"
				open={open}
				onClose={() => setIsDrawerOpen(false)}
				sx={{
					"& .MuiModal-backdrop": {
						backdropFilter: "blur(5px)",
					},
				}}
			>
				<div className={styles.drawer_content}>
					<img src={logo} alt="Shakespeare Logo" />
					<GradientText
						gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
						text="Shakespeare"
						style={{ fontSize: "2rem" }}
					/>
					<hr />
					<List className={styles.drawer_list}>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/kot-view", { replace: true });
								setIsDrawerOpen(false);
							}}
						>
							KOT View
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/table-view");
								setIsDrawerOpen(false);
							}}
						>
							Table View
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/completed-kots");
								setIsDrawerOpen(false);
							}}
						>
							Completed KOTs
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/preparation-recipes");
								setIsDrawerOpen(false);
							}}
						>
							Preparation Recipes
						</ListItem>
						{/* <ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/preparation-orders");
								setIsDrawerOpen(false);
							}}
						>
							Preparation Orders
						</ListItem> */}
						<ListItem
							onClick={() => {
								vibrator.tap();
								ordersContext.refreshOrders();
								setIsDrawerOpen(false);
							}}
						>
							Refresh Orders
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/settings");
								setIsDrawerOpen(false);
							}}
						>
							Settings
						</ListItem>
					</List>
					<button
						className={[commonStyles.secondary_button, styles.logout].join(" ")}
						onClick={() => {
							vibrator.tap();
							localStorage.clear();
							sessionStorage.clear();
							restaurantContext.clearRestaurant();
							cartContext.clearCart();
							ordersContext.clearOrders();
							navigate("/auth/login", { replace: true });
						}}
					>
						Log Out
					</button>
					<GradientBorder
						gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
						width="1px"
						className={styles.contact_block_wrapper}
					>
						<div className={styles.contact_block}>
							<div
								style={{
									fontSize: "18px",
									color: "#9E5E28",
									marginBottom: "8px",
									textAlign: "center",
								}}
							>
								<u>Help Line</u>
							</div>
							<div className={styles.contact_item}>
								<img src={whatsapp} alt="Whatsapp" />
								<a
									href={`https://wa.me/919849518781?text=Restaurant ID: ${localStorage.getItem(
										"restaurant"
									)}%0ARole: KITCHEN%0AUser Agent: ${
										navigator.userAgent
									}%0A------------%0AI have a query regarding:%0A`}
									target="_blank"
								>
									(91) 98495 18781
								</a>
							</div>
							<div className={styles.contact_item}>
								<MailRounded />
								<a
									href={`mailto:support@shakespeare.vision?subject=Kitchen POS Query&body=Restaurant ID: ${localStorage.getItem(
										"restaurant"
									)}%0ARole: KITCHEN%0AUser Agent: ${
										navigator.userAgent
									}%0A------------%0AI have a query regarding:%0A`}
								>
									support@shakespeare.vision
								</a>
							</div>
						</div>
					</GradientBorder>
				</div>
				<footer className={styles.drawer_footer}>
					<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
						www.shakespeare.vision
					</a>
				</footer>
			</MuiDrawer>
		</React.Fragment>
	);
};

export default Drawer;
