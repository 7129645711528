const vibrator = {
	tap: () => {
		if (navigator.vibrate) {
			navigator.vibrate(100);
		}
	},

	longTap: () => {
		if (navigator.vibrate) {
			navigator.vibrate(500);
		}
	},

	error: () => {
		if (navigator.vibrate) {
			navigator.vibrate(1000);
		}
	},

	success: () => {
		if (navigator.vibrate) {
			navigator.vibrate(200);
		}
	},

	warning: () => {
		if (navigator.vibrate) {
			navigator.vibrate(500);
		}
	},

	info: () => {
		if (navigator.vibrate) {
			navigator.vibrate(100);
		}
	},

	notification: () => {
		if (navigator.vibrate) {
			navigator.vibrate([100, 100, 100]);
		}
	},
};

export default vibrator;
