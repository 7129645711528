import React, { useState } from "react";
import Header from "../../components/Header/Header";
import styles from "./TableView.module.css";
import KOTGrid from "./KOTGrid/KOTGrid";
import TableGrid from "./TableGrid/TableGrid";
import commonStyles from "../../styles.module.css";
import { useNavigate } from "react-router-dom";

const TableView = () => {
	const [orderID, setOrderID] = useState(null);
	const [table, setTable] = useState({});

	const navigate = useNavigate();

	return (
		<div>
			<Header
				title="Kitchen Display"
				trailing={
					<div
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<button
							className={commonStyles.primary_button}
							onClick={() => navigate("/kot-view", { replace: true })}
						>
							KOT View
						</button>
						<button className={commonStyles.secondary_button}>
							Table View
						</button>
					</div>
				}
			/>
			<div className={styles.section}>
				<div className={styles.table_grid}>
					<TableGrid
						onClick={(orderID, table) => {
							setOrderID(orderID);
							setTable(table);
						}}
					/>
				</div>
				<div className={styles.kot_grid}>
					<KOTGrid orderID={orderID} table={table} />
				</div>
			</div>
		</div>
	);
};

export default TableView;
