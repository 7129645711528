import React, { useState, useContext } from "react";
import styles from "./Login.module.css";
import logo from "../../assets/images/shakespeare_logo.png";
import GradientText from "../../components/GradientText/GradientText";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import axios from "axios";
import toast from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { restaurant } from "../../providers/RestaurantProvider";
import vibrator from "../../utils/vibrator";
import logger from "../../utils/logger";
import ForgotPasswordDialog from "./ForgotPasswordDialog/ForgotPasswordDialog";

const Login = () => {
	const navigate = useNavigate();
	const restaurantContext = useContext(restaurant);
	const [showForgotPasswordDialog, setShowForgotPasswordDialog] =
		useState(false);

	const getItemsOfCategory = (category, items = []) => {
		logger(items);
		category.items.forEach((item) => {
			items.push(item);
		});
		category.subCategories.forEach((subCategory) => {
			getItemsOfCategory(subCategory, items);
		});
		return items;
	};

	const handleLogin = async (e) => {
		e.preventDefault();

		vibrator.tap();

		if (!email || !password) {
			toast.error("Please enter Email and Password");
			return;
		}

		try {
			const res = await axios.post("/restaurants/staff/login", {
				email,
				password,
				role: "KITCHEN",
			});
			localStorage.setItem("token", res.data.token);
			axios.defaults.headers.common["x-auth-token"] = res.data.token;

			if (!res.data.staff.restaurant) {
				toast.error(
					"You have not been assigned to any restaurant yet, please contact your admin"
				);
				return;
			}

			localStorage.setItem("restaurant", res.data.staff.restaurant);

			const restaurantRes = await axios.get(
				`/restaurants/${res.data.staff.restaurant}/unmorphed`
			);

			restaurantContext.setRestaurant(restaurantRes.data.restaurant);
			restaurantContext.setItems(restaurantRes.data.items);

			const categoryMappedItems = {};

			for (let cat of restaurantRes.data.restaurant.menu) {
				categoryMappedItems[cat.name] = getItemsOfCategory(cat);
				logger(categoryMappedItems);
			}

			restaurantContext.setCategoryWiseItems(categoryMappedItems);

			navigate("/kot-view", { replace: true });
		} catch (error) {
			logger(error);
			if (error.response?.status === 400) {
				toast.error("Invalid Email or Password");
			} else {
				toast.error(error?.response?.data?.message ?? "Network connection failed");
			}
		}
	};

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	return (
		<div className={styles.body}>
			<form className={styles.form}>
				<img src={logo} alt="Shakespeare Logo" />
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text="Shakespeare"
					style={{ fontSize: "1.8rem", marginBottom: "30px" }}
				/>
				<GradientBorder
					gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
					width="1px"
					className={styles.input_wrapper}
				>
					<input
						type="text"
						placeholder="Email"
						onChange={(e) => setEmail(e.target.value)}
					/>
				</GradientBorder>
				<GradientBorder
					gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
					width="1px"
					className={styles.input_wrapper}
				>
					<input
						type="password"
						placeholder="Password"
						onChange={(e) => setPassword(e.target.value)}
					/>
				</GradientBorder>
				<button className={styles.login_button} onClick={handleLogin}>
					Sign In
				</button>
				<div
					className={styles.forgot_password}
					onClick={() => {
						setShowForgotPasswordDialog(true);
					}}
				>
					Forgot Password?
				</div>
			</form>
			<footer>
				<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
					www.shakespeare.vision
				</a>
			</footer>
			<ForgotPasswordDialog
				open={showForgotPasswordDialog}
				setOpen={setShowForgotPasswordDialog}
			/>
		</div>
	);
};

export default Login;
