import React, { useContext, useEffect } from "react";
import Header from "../../components/Header/Header";
import CategoryItemsView from "./CategoryItemsView/CategoryItemsView";
import KOTCardView from "./KOTCardView/KOTCardView";
import { orders } from "../../providers/OrderProvider";
import styles from "./KOTView.module.css";
import logger from "../../utils/logger";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../styles.module.css";
import { kotContext } from "../../providers/KotProvider";

const KOTView = () => {
	const ordersContext = useContext(orders);
	const {refreshKots} = useContext(kotContext)

const navigate = useNavigate();

	useEffect(() => {
		logger("Orders", ordersContext.orders);
		if (Object.keys(ordersContext.orders || {}).length === 0)
			ordersContext.refreshOrders();
	}, []);

	return (
		<div className={styles.body}>
			<Header
				title="Kitchen Display"
				trailing={
					<div
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<button className={commonStyles.secondary_button}>KOT View</button>
						<button
							className={commonStyles.primary_button}
							onClick={() => navigate("/table-view", { replace: true })}
						>
							Table View
						</button>
					</div>
				}
			/>
			<div className={styles.section}>
				<div className={styles.category_wise}>
					<CategoryItemsView />
				</div>
				<div className={styles.kot_cards}>
					<KOTCardView />
				</div>
			</div>
		</div>
	);
};

export default KOTView;
