import { createContext, useState } from "react";

const settings = createContext();

const { Provider } = settings;

const SettingsProvider = ({ children }) => {
	const [settings, setStateSettings] = useState(
		JSON.parse(localStorage.getItem("settings")) || {
			animateOverdueKots: true,
			soundAlarm: true,
			alarmLoopCount: 3,
			newKotAlert: true,
		}
	);

	const setSettings = (settings) => {
		localStorage.setItem("settings", JSON.stringify(settings));
		setStateSettings(settings);
	};

	const updateSettings = (data) => {
		setStateSettings((prevSettings) => {
			const newSettings = { ...prevSettings, ...data };
			localStorage.setItem("settings", JSON.stringify(newSettings));
			return newSettings;
		});
	};

	const clearSettings = () => {
		setStateSettings({});
	};

	return (
		<Provider
			value={{
				settings,
				setSettings,
				clearSettings,
				updateSettings,
			}}
		>
			{children}
		</Provider>
	);
};

export { SettingsProvider, settings };
