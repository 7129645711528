import axios from "axios";
import React, { useState } from "react";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";
import { Dialog, Typography } from "@mui/material";
import GradientBorder from "../../GradientBorder/GradientBorder";
import styles from "./DeleteDialog.module.css";
import vibrator from "../../../utils/vibrator";
import commonStyles from "../../../styles.module.css";

const DeleteDialog = ({ open, setOpen, kot, item }) => {
	const [deletionReason, setDeletionReason] = useState("");
	const [password, setPassword] = useState("");

	const handleDelete = async () => {
		try {
			const newItems = kot.items
				.filter((i) => i.item._id !== item.item._id)
				.map((i) => {
					return {
						...i,
						item: i.item._id,
					};
				});

			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/kots/${kot._id}`,
				{
					items: newItems,
					deletion: {
						reason: deletionReason,
						item: item.item._id,
					},
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);
		} catch (err) {
			logger(err);
			if (err.isAxiosError && err.code === 'ERR_NETWORK') {
                toast.error("Network connection failed", {
                    autoClose: 2000
                })
            } else {
                  toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                      autoClose: 2000
                  })
            }
			
		} finally {
			setOpen(false);
		}
	};
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Confirm Deletion
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Password
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="password"
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Reason
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								value={deletionReason}
								onChange={(e) => {
									setDeletionReason(e.target.value);
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_button_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								setOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={() => {
								vibrator.tap();
								if (password === "st24632") {
									if (deletionReason === "") {
										toast.error("Please enter a reason", {
											autoClose: 2000,
										});
										return;
									}
									handleDelete();
								} else {
									toast.error("Incorrect Password", {
										autoClose: 2000,
									});
								}
							}}
						>
							Confirm
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default DeleteDialog;
