import { Popover, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { notifications } from "../../../providers/NotificationProvider";
import GradientBorder from "../../GradientBorder/GradientBorder";
import styles from "./NotificationsModal.module.css";
import { Delete } from "@mui/icons-material";
import vibrator from "../../../utils/vibrator";
import commonStyles from "../../../styles.module.css";
import ConfirmDialog from "./ConfirmDialog/ConfirmDialog";

const NotificationsModal = ({ open, setOpen, anchorEl }) => {
	const notificationsContext = useContext(notifications);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	return (
		<Popover
			open={open}
			onClose={() => setOpen(false)}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			sx={{
				"& .MuiPaper-root": {
					backgroundColor: "transparent",
					boxShadow: "none",
					height: "90%",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.modal_wrapper}
			>
				<div>
					<div className={styles.modal_header}>
						<Typography variant="h6" component="div" color="#9E5E28">
							Notifications
						</Typography>
					</div>
					<div className={styles.notifications}>
						{notificationsContext.notifications.length === 0 ? (
							<Typography variant="body1" component="div" color="#9E5E28">
								No notifications
							</Typography>
						) : (
							notificationsContext.notifications.map((notification, idx) => {
								return (
									<GradientBorder
										gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
										width="1px"
										className={styles.notification_wrapper}
									>
										<div className={styles.notification}>
											<div className={styles.notification_heading}>
												<Delete
													fontSize="small"
													onClick={() => {
														vibrator.tap();
														notificationsContext.removeNotification(idx);
													}}
												/>
												<Typography
													variant="body1"
													component="div"
													color="#9E5E28"
													sx={{
														fontWeight: "bold",
													}}
												>
													{notification.title}
												</Typography>
											</div>
											<Typography
												variant="body2"
												component="div"
												color="#9E5E28"
												sx={{
													marginBottom: "8px",
												}}
											>
												{notification.body}
											</Typography>
											<Typography
												variant="caption"
												component="div"
												color="#9E5E28"
												align="right"
												sx={{
													width: "100%",
												}}
											>
												{new Date(notification.time).toLocaleString()}
											</Typography>
										</div>
									</GradientBorder>
								);
							})
						)}
					</div>
					<div className={styles.modal_footer}>
						<div
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								setOpen(false);
							}}
							style={{
								fontSize: "14px",
							}}
						>
							Close
						</div>

						<div
							className={commonStyles.primary_button}
							onClick={() => {
								vibrator.tap();
								setShowConfirmDialog(true);
							}}
							style={{
								fontSize: "14px",
							}}
						>
							Clear All
						</div>
					</div>
				</div>
			</GradientBorder>
			<ConfirmDialog
				open={showConfirmDialog}
				setOpen={setShowConfirmDialog}
				handleConfirm={() => {
					vibrator.tap();
					notificationsContext.clearNotifications();
					setShowConfirmDialog(false);
				}}
			/>
		</Popover>
	);
};

export default NotificationsModal;
