import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import GradientBorder from "../../GradientBorder/GradientBorder";
import commonStyles from "../../../styles.module.css";
import styles from "./ConfirmDialog.module.css";

const ConfirmDialog = ({ open, setOpen, handleFulfill }) => {
	const handleCancel = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCancel}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div className={styles.dialog}>
					<DialogTitle>Fulfill KOT?</DialogTitle>
					<DialogContent>
						<div className={styles.dialog_content}>
							All the items in the KOT have been prepared. Do you want to
							fulfill the KOT?
						</div>
						<div className={styles.dialog_buttons}>
							<button
								className={commonStyles.secondary_button}
								onClick={handleCancel}
							>
								Cancel
							</button>
							<button
								className={commonStyles.primary_button}
								onClick={handleFulfill}
							>
								Fulfill
							</button>
						</div>
					</DialogContent>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default ConfirmDialog;
