import axios from "axios";
import { createContext, useState, useContext, useEffect } from "react";
import toast from "../utils/toast";

const kotContext = createContext({});
const { Provider } = kotContext;

const KotProvider = ({ children }) => {
	const [kots, setStateKots] = useState(
		JSON.parse(localStorage.getItem("kotData")) || 
        {}
	);

	const setKots = (kots) => {
        let kotData={}
        kots.forEach(kot=>{
            kotData[kot._id]=kot
        })

		localStorage.setItem("kotData", JSON.stringify(kotData));
		setStateKots({...kotData})
	};

	const updateKot= (kot)=>{
		setStateKots((prev)=>{
			localStorage.setItem("kotData", JSON.stringify({...prev, [kot._id]:kot}));
			return {...prev, [kot._id]:kot}
		})

	}

	const refreshKots = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/running`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setKots(res.data.kots)

		} catch (err) {
			if (err.isAxiosError && err.code === 'ERR_NETWORK') {
                toast.error("Network connection failed", {
                    autoClose: 2000
                })
            } else {
                  toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                      autoClose: 2000
                  })
            }
		}
	};

    useEffect(()=>{
        refreshKots()
    },[])

	const clearKot = () => {
		setStateKots({});
	};

	return (
		<Provider
			value={{
                kots,
				setKots,
				refreshKots,
				updateKot
			}}
		>
			{children}
		</Provider>
	);
};

export { KotProvider, kotContext };
