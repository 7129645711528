import React, { useEffect, useState, useContext } from "react";
import styles from "./KOTCard.module.css";
import { PrintRounded, RefreshRounded } from "@mui/icons-material";
import axios from "axios";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import ConfirmDialog from "./ConfirmDialog/ConfirmDialog";
import DeleteDialog from "./DeleteDialog/DeleteDialog";
import check from "../../assets/icons/check.png";
import cross from "../../assets/icons/cross.png";
import UnprepareDialog from "./UnprepareDialog/UnprepareDialog";
import { restaurant } from "../../providers/RestaurantProvider";
import alarmAudio from "../../assets/audio/overdue_alarm.aac";
import { settings } from "../../providers/SettingsProvider";
import { CircularProgress } from "@mui/material";

const KOTCard = ({ kot, order, table }) => {
	const [isPrinting, setIsPrinting]= useState(false)
	const getDuration = () => {
		const duration = Date.now() - Date.parse(kot.createdAt);
		const hours = Math.floor(duration / (1000 * 60 * 60));
		const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
		return `${hours}h ${minutes}m`;
	};

	logger("kot in kot card", kot)

	const [showFulfillDialog, setShowFulfillDialog] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showUnprepareDialog, setShowUnprepareDialog] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [unprepareIndex, setUnprepareIndex] = useState(null);
	const [kotItemIndex, setKotItemIndex] = useState(0)
	const [isOverdue, setIsOverdue] = useState(
		!kot.completed && Date.parse(kot.createdAt) + 1000 * 60 * 25 < Date.now()
	);
	const [overduePulse, setOverduePulse] = useState(false);
	const [duration, setDuration] = useState(getDuration());

	const restaurantContext = useContext(restaurant);
	const settingsContext = useContext(settings);

	let updateTimer = null;

	logger("isOverdue" + table.name, isOverdue)

	useEffect(() => {
		updateTimer = setInterval(() => {
			setDuration(getDuration());
			let isNowOverdue =
				!kot.completed &&
				Date.parse(kot.createdAt) + 25 * 60 * 1000 <= Date.now();
			if (!JSON?.parse(sessionStorage?.getItem('overdueTables'))?.[table.name]) {
				let overdueTables = JSON.parse(sessionStorage.getItem('overdueTables'))
				sessionStorage.setItem('overdueTables', JSON.stringify({ ...overdueTables, [table.name]: isOverdue }))
			}
			if (isNowOverdue !== JSON.parse(sessionStorage.getItem('overdueTables'))[table.name]) {
				setIsOverdue(isNowOverdue);
				let overdueTables = JSON.parse(sessionStorage.getItem('overdueTables'))
				sessionStorage.setItem('overdueTables', JSON.stringify({ ...overdueTables, [table.name]: true }))
				try {
					if (settingsContext.settings.soundAlarm) {
						const alarmLoopCount = settingsContext.settings.alarmLoopCount;
						setOverduePulse(true);
						playAudio(alarmLoopCount)
					}
				} catch (err) {
					logger(err);
				}
			}
		}, 60 * 1000);
		return () => {
			clearInterval(updateTimer);
		};
	}, []);

	function playAudio(times) {
		if (times <= 0) {
			setOverduePulse(false);
			return;
		}
		const audio = new Audio(alarmAudio);
		audio.currentTime = 0; // Reset audio to the beginning
		audio.play().catch(err => {
			logger(err)
			setOverduePulse(false);
		});
		audio.addEventListener('ended', function () {
			playAudio(times - 1);
		});
	}

	const markItemComplete = async (index) => {
		const kotCopy = JSON.parse(JSON.stringify(kot));
		kotCopy.items[index].prepared = !kotCopy.items[index].prepared;

		if (!kotCopy.items[index].prepared) {
			setUnprepareIndex(index);
			setShowUnprepareDialog(true);
			return;
		}

		try {
			if (kotCopy.items.every((item) => item.prepared)) {
				setKotItemIndex(index)
				setShowFulfillDialog(true);
			} else {
				const data = {
					prepared: true,
					itemID: kotCopy.items[index].item._id,
					properties: kotCopy.items[index].properties
				}
				const res = await axios.put(
					`restaurants/${localStorage.getItem("restaurant")}/kots/${kot._id}/prepared`,
					data,
					{
						headers: {
							"x-auth-token": localStorage.getItem("token"),
						},
					}
				);

				logger(res);
			}
		} catch (err) {
			logger(err);
			toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
				autoClose: 2000
			})
		}
	};
	const handlePrinting=async()=>{
		try{
			setIsPrinting(true)
			const {data} = await axios.get(
				`restaurants/${localStorage.getItem("restaurant")}/kots/${kot._id}/print`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			console.log(data)
				setIsPrinting(false)
				toast.success("KOT added to print queue")
			}catch(err){
			setIsPrinting(false)
			console.log(err)
			toast.error("Unable to print KOT")
		}
	}	

	return (
		<div
			className={
				styles.card +
				(overduePulse && settingsContext.settings.animateOverdueKots
					? ` ${styles.overdue_pulse}`
					: "")
			}
			id={kot._id}
		>
			<div
				className={styles.card_header + (kot.completed ? ` ${styles.completed}` : (isOverdue ? ` ${styles.overdue}` : ""))}
			>
				<div className={styles.kot_details}>
					<div className={styles.time}>{duration}</div>
					<div className={styles.table_name}>
						Table: {order?.table?.name || table?.name || "--"}
					</div>
					<div className={styles.table_name}>
						Name: {kot?.userData?.name || "--"}
					</div>
				</div>
				<div className={styles.cta_row}>
					{
						isPrinting ?
						<CircularProgress size={20}/> :
						<PrintRounded onClick={handlePrinting} /> 
					}
				</div>
			</div>
			<div className={styles.card_body}>
				{kot.items.map((item, index) => {
					return (
						<div
							key={index}
							className={
								styles.item +
								(!restaurantContext?.items?.[item?.item?._id]?.available
									? ` ${styles.unavailable}`
									: "")
							}
						>
							<div className={styles.item_quantity}>
								{item.quantity}&nbsp;&times;
							</div>

							<div className={styles.item_name}>
								{item.item.name}
								<div className={styles.properties}>
									{(item.properties || []).map((property) => {
										return (
											<div>
												{property.name} :{property?.option ? property?.option : property?.options?.join(", ") ?? ""}
											</div>
										);
									})}
								</div>
								{item.note && (
									<div className={styles.note}>Note: {item.note}</div>
								)}
							</div>
							{restaurantContext?.items?.[item.item._id]?.available ? (
								<div
									className={styles.cta}
									style={{
										marginTop: "2px",
									}}
								>
									<span
										className={!item.prepared && styles.unchecked}
										onClick={() => markItemComplete(index)}
									>
										{item.prepared && <img src={check} alt="Check Icon" />}
									</span>{" "}
									<span
										onClick={() => {
											setSelectedItem(item);
											setShowDeleteDialog(true);
										}}
									>
										<img src={cross} alt="Cross Icon" />
									</span>
									<div
										style={{ height: "35px" }}
									></div>

								</div>
							) : (
								<RefreshRounded
									sx={{
										margin: "0 8px",
									}}
									onClick={async () => {
										try {
											const res = await axios.put(
												`restaurants/${localStorage.getItem(
													"restaurant"
												)}/items/${item.item._id}/`,
												{
													available: true,
												},
												{
													headers: {
														"x-auth-token": localStorage.getItem("token"),
													},
												}
											);

											logger(res.data);
											toast.success("Item is now available");
										} catch (err) {
											logger(err);
											toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
												autoClose: 2000
											})
										}
									}}
								/>
							)}
						</div>
					);
				})}
			</div>
			<ConfirmDialog
				open={showFulfillDialog}
				setOpen={setShowFulfillDialog}
				kotID={kot._id}
				handleFulfill={async () => {
					try {
						const kotCopy = JSON.parse(JSON.stringify(kot));


						const data = {
							prepared: true,
							itemID: kotCopy.items[kotItemIndex].item._id,
							properties: kotCopy.items[kotItemIndex].properties,
							completed: true
						}


						const res = await axios.put(
							`restaurants/${localStorage.getItem("restaurant")}/kots/${kot._id
							}/prepared`,
							data,
							{
								headers: {
									"x-auth-token": localStorage.getItem("token"),
								},
							}
						);

						logger(res);
					} catch (err) {
						logger(err, "err in handle confirm");
						toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
							autoClose: 2000
						})
					} finally {
						setShowFulfillDialog(false);
					}
				}}
			/>
			<DeleteDialog
				open={showDeleteDialog}
				setOpen={setShowDeleteDialog}
				kot={kot}
				item={selectedItem}
			/>
			<UnprepareDialog
				open={showUnprepareDialog}
				setOpen={setShowUnprepareDialog}
				handleUnprepare={async (unprepareReason, wasted) => {
					try {
						const kotCopy = JSON.parse(JSON.stringify(kot));
						kotCopy.items[unprepareIndex].prepared = false;
						if (!kotCopy.preparationsRemoved) kotCopy.preparationsRemoved = [];
						kotCopy.preparationsRemoved.push({
							item: kotCopy.items[unprepareIndex].item._id,
							properties: kotCopy.items[unprepareIndex].properties,
							reason: unprepareReason,
						});
						kotCopy.completed = false;
						const data = {
							prepared: false,
							wasted, unprepareReason,
							itemID: kotCopy.items[unprepareIndex].item._id,
							properties: kotCopy.items[unprepareIndex].properties
						}
						const res = await axios.put(
							`restaurants/${localStorage.getItem("restaurant")}/kots/${kot._id
							}/prepared`,
							data,
							{
								headers: {
									"x-auth-token": localStorage.getItem("token"),
								},
							}
						);
						logger(res);
					} catch (err) {
						logger(err);
						toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
							autoClose: 2000
						})
					} finally {
						setShowUnprepareDialog(false);
					}
				}}
			/>
		</div>
	);
};

export default KOTCard;
