import React, { useContext, useState } from "react";
import { orders } from "../../../providers/OrderProvider";
import KOTCard from "../../../components/KOTCard/KOTCard";
import styles from "./KOTCardView.module.css";
import commonStyles from "../../../styles.module.css";
import OutOfStockModal from "../../../components/OutOfStockModal/OutOfStockModal";
import { RemoveShoppingCartRounded } from "@mui/icons-material";
import GradientText from "../../../components/GradientText/GradientText";
import Lottie from "lottie-react";
import diningTableAnimation from "../../../assets/lotties/dining-table.json";
import logger from "../../../utils/logger";
import { useNavigate } from "react-router-dom";
import { kotContext } from "../../../providers/KotProvider";

const KOTCardView = () => {
	const navigate = useNavigate()
	const ordersContext = useContext(orders);
	const {kots:allKots} = useContext(kotContext)
	const [isOutOfStockActive, setIsOutOfStockActive] = useState(false);

	const kots = Object.values(ordersContext.orders || {}).reduce((prev, curr) => {
		const incompleteKOTs = curr.kots
			.filter((kotId) =>{
				const kot = allKots[kotId]
				return  !kot?.completed && kot?.items.length > 0
			})
			.map((kotId) => {
				const kot = allKots[kotId]
				return {
					...kot,
					table: curr.table,
				};
			});
		return [...prev, ...incompleteKOTs];
	}, []);

	const buildKOTCardGrid = () => {
		const columns = [[], [], []];
		let ctr = 0;
		logger("kots in kot view",kots)
		kots.sort((a,b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
		kots.forEach((kot) => {
			if (kot?.completed) return;
			// logger(ctr)
			columns[ctr++ % columns.length].push(
				<KOTCard key={kot?._id} kot={kot} table={kot?.table} />
			);
			// logger(new Date(kot.createdAt).getTime())
			// columns.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime() )
					// logger(kot.createdAt)
			
		});

		return columns.map((column) => {
			// logger(column	)
			return <div className={styles.card_column}>{column}</div>;
		});
	};

	return (
		<div
			style={{
				height: "100%",
			}}
		>
			{kots.length > 0 && (
				<div className={styles.header_row}>
					<div className={styles.legends}>
						<div className={styles.legend}>
							<div
								className={styles.legend_color}
								style={{
									background:
										"linear-gradient(269.51deg, #008B59 -4.52%, #023C49 100%)",
								}}
							></div>
							<div className={styles.legend_text}>Running</div>
						</div>
						<div className={styles.legend}>
							<div
								className={styles.legend_color}
								style={{
									background:
										"linear-gradient(269.51deg, #8B0000 -4.52%, #D00303 100%)",
								}}
							></div>
							<div className={styles.legend_text}>Overdue</div>
						</div>
					</div>
					
							<button
								className={styles.button}
								onClick={() => {
									navigate("/completed-kots");
								}}
							>
								
								Completed KOTs
							</button>
							<button
								className={commonStyles.primary_button}
								onClick={() => {
									setIsOutOfStockActive(true);
								}}
							>
								<RemoveShoppingCartRounded
									sx={{
										marginRight: "4px",
									}}
								/>
								Mark Out of Stock
							</button>
				</div>
			)}
			{kots.length > 0 ? (
				<div className={styles.card_list}>{buildKOTCardGrid()}</div>
			) : (
				<div
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<GradientText
						gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
						style={{ textAlign: "center", width: "100%", fontSize: "30px" }}
						text={"No active tables, so relax Chef!"}
					/>
					<Lottie
						animationData={diningTableAnimation}
						style={{
							width: "50%",
							minWidth: "300px",
							marginTop: "20px",
						}}
					/>
				</div>
			)}
			<OutOfStockModal
				open={isOutOfStockActive}
				setOpen={setIsOutOfStockActive}
			/>
		</div>
	);
};

export default KOTCardView;
