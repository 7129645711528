import { Alarm, Circle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import styles from "./TableCell.module.css";
import vibrator from "../../../../utils/vibrator";
import GradientBorder from "../../../../components/GradientBorder/GradientBorder";
import { kotContext } from "../../../../providers/KotProvider";
import logger from "../../../../utils/logger";

const TableCell = ({ order, table, onClick }) => {
	const {kots} = useContext(kotContext)
	const getDuration = () => {
		const utcNow = new Date().getTime();
		const utcOrder = new Date(order.createdAt).getTime();
		const diff = utcNow - utcOrder;
		const minutes = Math.max(0, Math.floor(diff / 1000 / 60));
		const hours = Math.floor(minutes / 60);

		if (!minutes) {
			return "Just Updated";
		}

		if (minutes === 0) {
			return "Just Started";
		}
		if (hours > 0) {
			return `${hours} hr ${minutes % 60} min`;
		} else {
			return `${minutes} min`;
		}
	};

	logger("orderf", order)
	const checkIfOverdue = () => {
		if (!order.kots || order.kots.length === 0) {
			return false;
		}

		for (let kotId of order.kots) {
			const kot = kots[kotId]
			if (kot?.completed) {
				continue;
			}

			const now = Date.now();
			const kotTime = Date.parse(kot?.createdAt);

			const diff = now - kotTime;

			const minutes = Math.max(0, Math.floor(diff / 1000 / 60));

			if (minutes >= 25) {
				return true;
			}
		}

		return false;
	};

	const [time, setTime] = useState(getDuration());
	const [isOverdue, setIsOverdue] = useState(checkIfOverdue());

	useEffect(() => {
		const timer = setInterval(() => {
			setTime(getDuration());
			setIsOverdue(checkIfOverdue());
		}, 60000);

		return () => {
			clearInterval(timer);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order]);

	const handleClick = () => {
		vibrator.tap();
		onClick(order._id, table);
	};

	const isPending = order.kots?.some((kotId) => {
		const kot = kots[kotId];
		return !kot.completed
	}) ?? false;

	return (
		<GradientBorder
			gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
			width="1px"
			className={styles.table_cell_wrapper}
		> 
			<div
				className={
					styles.table_cell +
					(isOverdue ? ` ${styles.overdue}` : "") +
					(isPending && " " + styles[order.status])
				}
				onClick={handleClick}
			>
				<div className={styles.icon_row}>
					<Alarm
						htmlColor={"transparent"}
						sx={{
							fontSize: "14px",
						}}
					/>
					<Circle
						sx={{
							fontSize: "14px",
						}}
						htmlColor={"transparent"}
					/>
				</div>
				<div>
				{/* {(isOverdue || isPending) ? 
					<Typography
						variant="caption"
						sx={{
							fontSize: "0.7rem",
						}}
						component="div"
						// color={"transparent"}
					>
						{time}
					</Typography>
					: */}
					<Typography
						variant="caption"
						sx={{
							fontSize: "0.7rem",
						}}
						component="div"
						color={"transparent"}
					>
						{time}
					</Typography>
				{/* } */}
					<Typography
						variant="h4"
						component="div"
						fontWeight={500}
						lineHeight={0.9}
						align="center"
					>
						{table.name}
					</Typography>
					<Typography
						variant="caption"
						sx={{
							fontSize: "0.7rem",
						}}
						component="div"
						align="center"
					>
						{(isOverdue || isPending) ? `${order.kots?.length ? order.kots.length : console.log(order, "orderfff")} KOT(s)` : ``}
					</Typography>
				</div>
			</div>
		</GradientBorder>
	);
};

export default TableCell;
