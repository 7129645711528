import React, { useContext } from "react";
import { restaurant } from "../../../providers/RestaurantProvider";
import { orders } from "../../../providers/OrderProvider";
import { Typography } from "@mui/material";
import { Alarm, Circle } from "@mui/icons-material";
import styles from "./TableGrid.module.css";
import TableCell from "./TableCell/TableCell";

const TableGrid = ({ onClick }) => {
	const restaurantContext = useContext(restaurant);
	const ordersContext = useContext(orders);

	// const tableWiseOrders = Object.values(ordersContext.orders)?.reduce(
	// 	(acc, order) => {
	// 		if (order.table) {
	// 			acc[order.table._id] = order;
	// 		}
	// 		return acc;
	// 	},
	// 	{}
	// );
	const tableWiseOrders = {}
	 Object.values(ordersContext.orders)?.map(
		(order)=>{
			tableWiseOrders[order.table._id]= order
		}
	);

	const buildTableGrid = () => {
		return (
			<>
				{restaurantContext.restaurant.sections.map((section) => {
					return (
						<div key={section.name}>
							<div className={styles.section_header}>
								<Typography
									variant="subtitle1"
									component="div"
									sx={{
										marginLeft: "12px",
										color: "#824500",
									}}
								>
									{section.name}
								</Typography>
								<div className={styles.legend}>
									<div className={styles.legend_item}>
										<div
											className={styles.legend_color}
											style={{
												background:
													"linear-gradient(136.53deg, #8B0000 25.67%, #D00303 79.82%)",
											}}
										/>
										<Typography
											variant="body2"
											component="div"
											sx={{
												marginLeft: "4px",
												color: "#C2A186",
											}}
										>
											Overdue
										</Typography>
									</div>
									<div className={styles.legend_item}>
										<div
											className={styles.legend_color}
											style={{
												background:
													"linear-gradient(269.51deg, #008B59 -4.52%, #023C49 100%)",
											}}
										/>
										<Typography
											variant="body2"
											component="div"
											sx={{
												marginLeft: "4px",
												color: "#C2A186",
											}}
										>
											Running
										</Typography>
									</div>
								</div>
							</div>
							<div className={styles.table_grid}>
								{section.tables.map((table) => {
									return (
										<TableCell
											key={table._id}
											order={tableWiseOrders[table._id] || {}}
											table={table}
											onClick={onClick}
										/>
									);
								})}
							</div>
						</div>
					);
				})}
			</>
		);
	};

	return <div>{buildTableGrid()}</div>;
};

export default TableGrid;
