import React, { useState } from "react";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import { Dialog, Typography } from "@mui/material";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./PreparationInput.module.css";
import vibrator from "../../utils/vibrator";
import commonStyles from "../../styles.module.css";
import { mySocket } from "../../components/SocketWrapper/SocketWrapper";
import axios from "axios";
import PreparationRecipeDownload from "./PreparationRecipeDownload";


const PreparationInputDialog = ({ open, setOpen, recipe, recipeRef, setSelectedRecipe }) => {
	const [quantity, setQuantity] = useState(0);
	


	const handleConfirm = async (e) => {
		e.stopPropagation()
		if (quantity <= 0) {
			toast.error("Please choose quantity above 0")
			return
		}
		try {
			setSelectedRecipe({
			...recipe,
				preparationQuantity:quantity
			})
			
			// const res = await axios.post(
			// 	`/restaurants/${localStorage.getItem(
			// 		"restaurant"
			// 	)}/recipe/preparationRecipe/order/create`,
			// 	{ quantity, recipeId: recipe._id, property: recipe.property, option:recipe.option },
			// 	{
			// 		headers: {
			// 			"x-auth-token": localStorage.getItem("token"),
			// 		},
			// 	}
			// );
			// toast.success("Successfully added preparation order", {
			// 	autoClose: 2000
			// })

		} catch (err) {
			console.log(err)
			// if (err.isAxiosError && err.code === 'ERR_NETWORK') {
            //     toast.error("Network connection failed", {
            //         autoClose: 2000
            //     })
            // } else {
            //       toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
            //           autoClose: 2000
            //       })
            // }
		}
		setOpen(false)
	};
	return (
		<>
		<Dialog
			open={open}
			onClose={(e) => {
				e.stopPropagation()
				setOpen(false)
			}}

			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Confirm Print
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Quantity
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="number"
								value={quantity}
								onChange={(e) => {
									setQuantity(e.target.value);
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Unit
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								disabled
								value={recipe.unit}

							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_button_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={(e) => {
								e.stopPropagation()
								vibrator.tap();
								setOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={handleConfirm}
						>
							Confirm
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>

			</>
	);
};

export default PreparationInputDialog;
