import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import styles from './preparation.module.css'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default function PreparationOrderView({ recipeData }) {
    if (!recipeData?.recipe) {
        return null
    }
    const { recipe, quantity, itemName } = recipeData;
    const quantityToMultiply = quantity / recipe?.quantity ?? 0


    return (

        <div style={{ padding: "20px" }} className={styles.myDrawer}>

            <Table stickyHeader>
                <TableRow style={{ fontWeight: "500" }}>
                    {itemName}
                    -
                    {(recipe.option ? recipe.option : null )}

                </TableRow>
            </Table>
            {
                recipe?.ingredients?.[0] &&
                <>
                    <Table stickyHeader
                        style={{
                            widTableCell: "100%",
                            marginTop: "10px"
                        }}>
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#FEF2E2",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

                                    "& .MuiTableCell-root": {
                                        fontWeight: "bold",
                                        color: "#9E5E28",
                                        backgroundColor: "#FEF2E2",
                                    },
                                }}
                            >
                                <TableCell>
                                    <span style={{ fontWeight: "bolder" }}>Item</span>
                                </TableCell>
                                <TableCell>
                                    <span style={{ fontWeight: "bolder" }}>Qty.</span>
                                </TableCell>
                                <TableCell>
                                    <span style={{ fontWeight: "bolder" }}>Unit</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(recipe?.ingredients ?? []).map((ingredient, index) => {

                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <span style={{ fontWeight: "bolder" }}>
                                                {ingredient.name}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <span style={{ fontWeight: "500" }}>{(ingredient.quantity * quantityToMultiply).toFixed(2)}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span style={{ fontWeight: "500" }}>
                                                {ingredient.unit}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <div style={{ margin: "30px" }}>
                        <b>Chopping Method</b>
                        <br />
                        <span>{recipe.choppingMethod ?? "--"}</span>
                    </div>
                    <div style={{ margin: "30px",  marginTop:"0px" }}>
                        <b>Process</b>
                        <ul>
                            {(recipe?.process?.split("\n") ?? []).map((step) => {
                                return <li style={{ maxWidTableCell: "480px" }}>{step}</li>
                            })}
                        </ul>
                    </div>
                </>
            }

        </div>

    )
}
