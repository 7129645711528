import React, { useContext, useEffect, useState } from "react";
import { orders } from "../../../providers/OrderProvider";
import { restaurant } from "../../../providers/RestaurantProvider";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import logger from "../../../utils/logger";
import styles from "./CategoryItemsView.module.css";
import { kotContext } from "../../../providers/KotProvider";

const CategoryItemsView = () => {
	const ordersContext = useContext(orders);
	const restaurantContext = useContext(restaurant);
	const {kots} = useContext(kotContext)

	const categoryMappedItems = Object.values(ordersContext.orders || {})?.reduce(
		(prevOrd, order) => {
			const currOrderDetails = order.kots?.reduce((prevKot, kotId) => {
				const kot = kots[kotId]
				if (kot?.completed) return prevKot;

				const currKOTDetails = kot?.items?.reduce((prevItem, item) => {
					const category = Object.keys(
						restaurantContext.categoryWiseItems
					).find((i) => {
						return restaurantContext.categoryWiseItems[i].includes(
							item.item._id
						);
					});

					const ret = { ...prevItem };

					if (category in ret) {
						if (item.item._id in ret[category]) {
							ret[category][item.item._id] += item.quantity;
						} else {
							ret[category][item.item._id] = item.quantity;
						}
					} else {
						ret[category] = {
							[item.item._id]: item.quantity,
						};
					}

					return ret;
				}, {});

				const ret = { ...prevKot };

				for (let k of Object.keys(currKOTDetails || {})) {
					if (k in ret) {
						for (let i of Object.keys(currKOTDetails[k])) {
							// Use for...of loop here
							if (i in ret[k]) {
								ret[k][i] += currKOTDetails[k][i];
							} else {
								ret[k][i] = currKOTDetails[k][i];
							}
						}
					} else {
						ret[k] = currKOTDetails[k];
					}
				}

				return ret;
			}, {});

			const ret = { ...prevOrd };

			for (let k of Object.keys(currOrderDetails)) {
				if (k in ret) {
					for (let i of Object.keys(currOrderDetails[k])) {
						// Use for...of loop here
						if (i in ret[k]) {
							ret[k][i] += currOrderDetails[k][i];
						} else {
							ret[k][i] = currOrderDetails[k][i];
						}
					}
				} else {
					ret[k] = currOrderDetails[k];
				}
			}

			return ret;
		},
		{}
	);

	logger(categoryMappedItems);

	return (
		<div className={styles.body}>
			{Object.entries(categoryMappedItems).map(([category, items]) => {
				return (
					<Accordion>
						<AccordionSummary
							sx={{
								background:
									"linear-gradient(89.51deg, rgba(139, 70, 0, 0.85) -4.52%, rgba(73, 37, 2, 0.85) 100%)",
								backgroundColor: "#000000",
								color: "#FFFFFF",
								textAlign: "center",
								fontSize: "1.1rem",
								"& .MuiAccordionSummary-content": {
									justifyContent: "center",
								},
							}}
						>
							{category}
						</AccordionSummary>
						<AccordionDetails
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								backgroundColor: "transparent",
							}}
						>
							{Object.entries(items).map(([id, qty]) => {
								return (
									<div className={styles.item_row}>
										<span>{restaurantContext.items[id]?.name}</span>{" "}
										<span className={styles.qty}>{qty}</span>
									</div>
								);
							})}
						</AccordionDetails>
					</Accordion>
				);
			})}
		</div>
	);
};

export default CategoryItemsView;
