import React, { useContext } from "react";
import Header from "../../components/Header/Header";
import { orders } from "../../providers/OrderProvider";
import styles from "./CompletedKOTs.module.css";
import KOTCard from "../../components/KOTCard/KOTCard";
import logger from "../../utils/logger";
import { kotContext } from "../../providers/KotProvider";

const CompletedKOTs = () => {
	const ordersContext = useContext(orders);
	const {kots:allKots} = useContext(kotContext)

	const kots = Object.values(ordersContext.orders).reduce((prev, curr) => {
		const completeKOTs = curr.kots
			.filter((kotId) =>{
				const kot = allKots[kotId]
				return kot.completed && kot.items.length > 0
			})
			.map((kotId) => {
				const kot = allKots[kotId]
				return {
					...kot,
					table: curr.table,
				};
			});
		return [...prev, ...completeKOTs];
	}, []);
	logger("Completed?", kots)
	const buildKOTCardGrid = () => {
		const columns = [[], [], [], []];

		let ctr = 0;
		logger("kot", kots)
		kots.sort(
			(a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
		)
		logger("proper",Object.values(ordersContext.orders))
		kots.forEach((kot) => {
			// logger(order)
			// order.kots.forEach((kot) => {
				if (!kot.completed) return;
				columns[ctr++ % columns.length].push(
					<KOTCard key={kot._id} kot={kot} table={kot.table} />
				);
			// });
		});

		return columns.map((column) => {
			return <div className={styles.card_column}>{column}</div>;
		});
	};

	return (
		<div>
			<Header title="Completed KOTs" />
			<div className={styles.card_list}>{buildKOTCardGrid()}</div>
		</div>
	);
};

export default CompletedKOTs;
