import React, { useContext } from "react";
import KOTCard from "../../../components/KOTCard/KOTCard";
import styles from "./KOTGrid.module.css";
import diningTableAnimation from "../../../assets/lotties/dining-table.json";
import Lottie from "lottie-react";
import GradientText from "../../../components/GradientText/GradientText";
import { orders } from "../../../providers/OrderProvider";
import { kotContext } from "../../../providers/KotProvider";

const KOTGrid = ({ orderID, table }) => {
	const ordersContext = useContext(orders);
	const order = ordersContext.orders[orderID];
	const {kots:allKots} = useContext(kotContext)
	const kots = order?.kots || [];
	const filteredKOTs = kots.filter((kotId) => {
		const kot = allKots[kotId]
		return !kot.completed;
	});

	const buildKOTCardGrid = () => {
		const columns = [[], []];

		let ctr = 0;

		filteredKOTs.forEach((kotId) => {
			const kot = allKots[kotId]

			columns[ctr++ % columns.length].push(
				<KOTCard key={kot._id} kot={kot} table={table} />
			);
		});

		return columns.map((column) => {
			return <div className={styles.card_column}>{column}</div>;
		});
	};

	return filteredKOTs.length === 0 || kots.length === 0 ? (
		<div
			style={{
				width: "100%",
				minHeight: "calc(100vh - 64px)",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<GradientText
				gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
				style={{ textAlign: "center", width: "100%", fontSize: "30px" }}
				text={
					!orderID
						? "Please Select a Running Table"
						: "All KOT(s) have been fulfilled"
				}
			/>
			<Lottie
				animationData={diningTableAnimation}
				style={{
					width: "50%",
					minWidth: "300px",
					marginTop: "20px",
				}}
			/>
		</div>
	) : (
		<div className={styles.card_list}>{buildKOTCardGrid()}</div>
	);
};

export default KOTGrid;
