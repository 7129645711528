import React, { useEffect, useState } from 'react'
import styles from './PreparationRecipeDownloadStyle.module.css'
import { Table, tbody, th, thead, tr } from '@mui/material';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

export default function PreparationRecipeDownload({recipeRef, recipe, setSelectedRecipe}) {
    const quantityToMultiply = (recipe?.preparationQuantity ?? 0) / recipe?.quantity ?? 1
    const handlePrint = useReactToPrint({
		content: () => recipeRef.current,
	  });
    useEffect(()=>{
        if(recipe?.ingredients){
            handlePrint()
            setSelectedRecipe({})

        }
    },[recipe])
    return (
        <div className={styles.main}>
        <div style={{ padding: "20px" }} ref={recipeRef} className={styles.recipeContainer} >

            <Table stickyHeader>
                <tr style={{ fontWeight: "500" }}>
                {(recipe?.item?.name ?? recipe.option )}
                </tr>
            </Table>
            {
                recipe?.ingredients?.[0] &&
                <>
                    <table stickyHeader
                        style={{
                            width: "100%",
                            marginTop: "10px"
                        }}>
                        <thead>
                            <tr
                                sx={{
                                    backgroundColor: "#FEF2E2",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

                                    "& .Muith-root": {
                                        fontWeight: "bold",
                                        color: "#9E5E28",
                                        backgroundColor: "#FEF2E2",
                                    },
                                }}
                            >
                                <th>
                                    <span style={{ fontWeight: "bolder" }}>Item</span>
                                </th>
                                <th>
                                    <span style={{ fontWeight: "bolder" }}>Qty.</span>
                                </th>
                                <th>
                                    <span style={{ fontWeight: "bolder" }}>Unit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {( recipe?.ingredients ?? []).map((ingredient, index) => {

                                return (
                                    <tr key={index}>
                                        <th>
                                            <span style={{ fontWeight: "bolder" }}>
                                                {ingredient?.name}
                                            </span>
                                        </th>
                                        <th>
                                            <span style={{ fontWeight: "500" }}>{(ingredient?.quantity * quantityToMultiply).toFixed(2)}</span>
                                        </th>
                                        <th>
                                            <span style={{ fontWeight: "500" }}>
                                                {ingredient?.unit}
                                            </span>
                                        </th>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div style={{ margin: "30px" }}>
                        <b>Chopping Method</b>
                        <br />
                        <span>{recipe?.choppingMethod ?? "--"}</span>
                    </div>
                    <div style={{ margin: "30px",  marginTop:"0px" }}>
                        <b>Process</b>
                        <ul>
                            {(recipe?.process?.split("\n") ?? []).map((step) => {
                                return <li style={{ maxWidth: "480px" }}>{step}</li>
                            })}
                        </ul>
                    </div>
                </>
            }

        </div>
        </div>

    )
}
