import axios from "axios";
import { createContext, useState } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";

const restaurant = createContext({});
const { Provider } = restaurant;

const RestaurantProvider = ({ children }) => {
	const [restaurant, setStateRestaurant] = useState(
		JSON.parse(localStorage.getItem("restaurantData")) || {}
	);
	const [items, setStateItems] = useState(
		JSON.parse(localStorage.getItem("restaurantItems")) || {}
	);
	const [categoryWiseItems, setStateCategoryWiseItems] = useState(
		JSON.parse(localStorage.getItem("categoryWiseItems")) || {}
	);

	const setCategoryWiseItems = (items) => {
		localStorage.setItem("categoryWiseItems", JSON.stringify(items));
		setStateCategoryWiseItems(items);
	};

	const setItems = (items) => {
		localStorage.setItem("restaurantItems", JSON.stringify(items));
		setStateItems(items);
	};

	const setRestaurant = (restaurant) => {
		localStorage.setItem("restaurantData", JSON.stringify(restaurant));
		setStateRestaurant(restaurant);
	};

	const updateItem = (itemID, item) => {
		setStateItems((prevItems) => {
			const newItems = { ...prevItems, [itemID]: item };
			localStorage.setItem("restaurantItems", JSON.stringify(newItems));
			return newItems;
		});
	};

	const updateRestaurant = (data) => {
		setStateRestaurant((prevRestaurant) => {
			const newRestaurant = { ...prevRestaurant, ...data };
			localStorage.setItem("restaurantData", JSON.stringify(newRestaurant));
			return newRestaurant;
		});
	};

	const refreshRestaurant = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/unmorphed`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			setRestaurant(res.data.restaurant);
			setItems(res.data.items);
			toast.success("Restaurant refreshed", {
				autoClose: 2000,
			});
		} catch (err) {
			if (err.isAxiosError && err.code === 'ERR_NETWORK') {
                toast.error("Network connection failed", {
                    autoClose: 2000
                })
            } else {
                  toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                      autoClose: 2000
                  })
            }
		}
	};

	const updateAvailability = async (
		itemId,
		available,
		unavailableReason = undefined
	) => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/items/${itemId}`,
				{
					available,
					unavailableReason,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			updateItem(itemId, res.data);
		} catch (err) {
			logger(err);
			if (err.isAxiosError && err.code === 'ERR_NETWORK') {
                toast.error("Network connection failed", {
                    autoClose: 2000
                })
            } else {
                  toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                      autoClose: 2000
                  })
            }
		}
	};

	const updateTable = (data) => {
		setStateRestaurant((prevRestaurant) => {
			const newRestaurant = { ...prevRestaurant };
			const sectionIndex = newRestaurant.sections.findIndex(
				(section) => section.name === data.section
			);
			const tableIndex = newRestaurant.sections[sectionIndex].tables.findIndex(
				(table) => table._id === data._id
			);
			if (tableIndex === -1) {
				newRestaurant.sections[sectionIndex].tables.push(data);
			} else {
				newRestaurant.sections[sectionIndex].tables[tableIndex] = data;
			}
			localStorage.setItem("restaurantData", JSON.stringify(newRestaurant));
			return newRestaurant;
		});
	};

	const removeTable = (tableID) => {
		setStateRestaurant((prevRestaurant) => {
			const newRestaurant = { ...prevRestaurant };
			const sectionIndex = newRestaurant.sections.findIndex((section) =>
				section.tables.map((table) => table._id).includes(tableID)
			);
			if (sectionIndex === -1) return newRestaurant;
			const tableIndex = newRestaurant.sections[sectionIndex].tables.findIndex(
				(table) => table._id === tableID
			);
			if (tableIndex === -1) return newRestaurant;
			newRestaurant.sections[sectionIndex].tables.splice(tableIndex, 1);
			localStorage.setItem("restaurantData", JSON.stringify(newRestaurant));
			return newRestaurant;
		});
	};

	const clearRestaurant = () => {
		setStateRestaurant({});
	};

	return (
		<Provider
			value={{
				restaurant,
				setRestaurant,
				refreshRestaurant,
				clearRestaurant,
				items,
				updateAvailability,
				setItems,
				updateItem,
				updateRestaurant,
				categoryWiseItems,
				setCategoryWiseItems,
				updateTable,
				removeTable,
			}}
		>
			{children}
		</Provider>
	);
};

export { RestaurantProvider, restaurant };
