import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import Login from "./pages/Login/Login";
import KOTView from "./pages/KOTView/KOTView";
import NotFound from "./pages/NotFound/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SocketWrapper from "./components/SocketWrapper/SocketWrapper";
import { NotificationProvider } from "./providers/NotificationProvider";
import { SettingsProvider } from "./providers/SettingsProvider";
import { RestaurantProvider } from "./providers/RestaurantProvider";
import { OrderProvider } from "./providers/OrderProvider";
import { CartProvider } from "./providers/CartProvider";
import TableView from "./pages/TableView/TableView";
import CompletedKOTs from "./pages/CompletedKOTs/CompletedKOTs";
import Settings from "./pages/Settings/Settings";
import { KotProvider } from "./providers/KotProvider";
import ProtectedComponent from "./components/ProtectedComponent/ProtectedComponent";
import PreparationRecipes from "./pages/PreparationRecipes/PreparationRecipes";
import PreparationOrders from "./pages/PreparationOrders/PreparationOrders";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SplashScreen />,
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/kot-view",
    element: <ProtectedComponent Component={KOTView} />,
  },
  {
    path: "/table-view",
    element: <ProtectedComponent Component={TableView} />,
  },
  {
    path: "/completed-kots",
    element: <ProtectedComponent Component={CompletedKOTs} />,
  },
  {
    path: "/preparation-recipes",
    element: <ProtectedComponent Component={PreparationRecipes} />,
  },
  {
    path: "/preparation-orders",
    element: <ProtectedComponent Component={PreparationOrders} />,
  },

  {
    path: "/settings",
    element: <ProtectedComponent Component={Settings} />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NotificationProvider>
      <SettingsProvider>
        <RestaurantProvider>
          <KotProvider>
            <OrderProvider>
              <CartProvider>
                <SocketWrapper>
                  <RouterProvider router={router} />
                </SocketWrapper>
              </CartProvider>
            </OrderProvider>
          </KotProvider>
        </RestaurantProvider>
      </SettingsProvider>
    </NotificationProvider>
    <ToastContainer />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
