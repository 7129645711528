import { createContext, useState } from "react";
import logger from "../utils/logger";
const notifications = createContext();

const { Provider } = notifications;

const NotificationProvider = ({ children }) => {
	const [notifications, setStateNotifications] = useState(
		JSON.parse(localStorage.getItem("notifications")) || []
	);
	const [notificationsEnabled, setStateNotificationsEnabled] = useState(
		JSON.parse(localStorage.getItem("notificationsEnabled")) || false
	);

	const setNotifications = (notification) => {
		localStorage.setItem("notifications", JSON.stringify(notification));
		setStateNotifications(notification);
	};

	const setNotificationsEnabled = (enabled) => {
		logger(
			"NotificationProvider.js",
			"setNotificationsEnabled",
			"notificationsEnabled",
			enabled
		);
		localStorage.setItem("notificationsEnabled", JSON.stringify(enabled));
		setStateNotificationsEnabled(enabled);
	};

	const clearNotifications = () => {
		setNotifications([]);
	};

	const addNotification = async (notification) => {
		setStateNotifications((prev) => {
			const newNotifications = [{ ...notification, time: Date.now() }, ...prev];
			localStorage.setItem("notifications", JSON.stringify(newNotifications));
			return newNotifications;
		});
	};

	const removeNotification = (index) => {
		setStateNotifications((prev) => {
			const newNotifications = [...prev];
			newNotifications.splice(index, 1);
			localStorage.setItem("notifications", JSON.stringify(newNotifications));
			return newNotifications;
		});
	};

	return (
		<Provider
			value={{
				notifications,
				setNotifications,
				clearNotifications,
				notificationsEnabled,
				setNotificationsEnabled,
				addNotification,
				removeNotification,
			}}
		>
			{children}
		</Provider>
	);
};

export { NotificationProvider, notifications };
