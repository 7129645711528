import React from 'react'
import styles from './menuDownloadStyle.module.css'
export default function CurrentMenuDownload({ myRef, selectedItem }) {
  let propertWiseItems = {}
  selectedItem?.properties?.forEach(prop => {
    propertWiseItems[prop?.name?.toLowerCase().trim()] = prop
  })
  if(propertWiseItems['soft fry']){
    const keyName="soft fry"
    const propItem= propertWiseItems[keyName]
    delete propertWiseItems[keyName]
    propertWiseItems={
      [keyName]:propItem,
      ...propertWiseItems
    }
  }
  if(propertWiseItems['fry']){
    const keyName="fry"
    const propItem= propertWiseItems[keyName]
    delete propertWiseItems[keyName]
    propertWiseItems={
      [keyName]:propItem,
      ...propertWiseItems
    }
  }
  if(propertWiseItems['bread']){
    const keyName="bread"
    const propItem= propertWiseItems[keyName]
    delete propertWiseItems[keyName]
    propertWiseItems={
      [keyName]:propItem,
      ...propertWiseItems
    }
  }
  if(propertWiseItems['sweets']){
    const keyName="sweets"
    const propItem= propertWiseItems[keyName]
    delete propertWiseItems[keyName]
    propertWiseItems={
      [keyName]:propItem,
      ...propertWiseItems
    }
  }

  console.log(selectedItem)
  return (
    <div className={styles.main} ref={myRef}>
      <div className={styles.container}>
        <b className={styles.date}>
          DATE : {new Date().toLocaleDateString()}
        </b>
        <div className={styles.head}>
          <h1>E ROJU BHOJANAM LO</h1>
          <div className={styles.head_price}>
            <h2>{selectedItem?.price}</h2>
            &nbsp;
            <h5>+Taxes</h5>
          </div>
        </div>
        {
          Object.values(propertWiseItems ?? {}).filter(prop => prop.options[0]).map(prop => {
            return <>
              <div className={styles.subHead}>{prop.name}</div>
              {prop.options.map(item => {
                return <div className={styles.item}>
                  {item.name}
                </div>
              })}
            </>
          })
        }


      </div>
    </div>
  )
}
