import { IconButton, List, ListItem, Switch } from "@mui/material";
import React, { useContext } from "react";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import Header from "../../components/Header/Header";
import { restaurant } from "../../providers/RestaurantProvider";
import { settings } from "../../providers/SettingsProvider";
import vibrator from "../../utils/vibrator";
import styles from "./Settings.module.css";
import commonStyles from "../../styles.module.css";
import { useNavigate } from "react-router-dom";
import { PlayCircle } from "@mui/icons-material";
import alarmAudio from "../../assets/audio/overdue_alarm.aac";
import kotAlert from "../../assets/audio/kot_alert.aac";
import logger from "../../utils/logger";

const Settings = () => {
	const restaurantContext = useContext(restaurant);
	const settingsContext = useContext(settings);

	const navigate = useNavigate();

	return (
		<div
			style={{
				backgroundColor: "#FEFAF3",
				minHeight: "calc(100vh)",
			}}
		>
			<Header title="Settings" />
			<List>
				<ListItem
					sx={{
						justifyContent: "center",
					}}
				>
					<GradientBorder
						gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
						width="1px"
						className={styles.setting_row}
					>
						<div>
							<div>Animate Overdue KOTs</div>
							<Switch
								checked={settingsContext.settings.animateOverdueKots}
								onChange={() => {
									settingsContext.updateSettings({
										...settingsContext.settings,
										animateOverdueKots:
											!settingsContext.settings.animateOverdueKots,
									});
								}}
								sx={{
									"& .MuiSwitch-switchBase.Mui-checked": {
										color: "#9E5E28",
									},
									"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
										backgroundColor: "#9E5E28",
									},
								}}
							/>
						</div>
					</GradientBorder>
				</ListItem>
				<ListItem
					sx={{
						justifyContent: "center",
					}}
				>
					<GradientBorder
						gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
						width="1px"
						className={styles.setting_row}
					>
						<div>
							<div>Sound Alarm for overdue KOTs</div>
							<Switch
								checked={settingsContext.settings.soundAlarm}
								onChange={() => {
									settingsContext.updateSettings({
										...settingsContext.settings,
										soundAlarm: !settingsContext.settings.soundAlarm,
									});
								}}
								sx={{
									"& .MuiSwitch-switchBase.Mui-checked": {
										color: "#9E5E28",
									},
									"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
										backgroundColor: "#9E5E28",
									},
								}}
							/>
						</div>
					</GradientBorder>
				</ListItem>
				<ListItem
					sx={{
						justifyContent: "center",
					}}
				>
					<GradientBorder
						gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
						width="1px"
						className={styles.setting_row}
					>
						<div>
							<div>Alarm Loop Count</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<input
									type="number"
									min="1"
									max="10"
									value={settingsContext.settings.alarmLoopCount}
									onChange={(e) => {
										settingsContext.updateSettings({
											...settingsContext.settings,
											alarmLoopCount: parseInt(e.target.value),
										});
									}}
									className={styles.alarm_loop_count_input}
								/>
								<PlayCircle
									fontSize="large"
									sx={{
										marginLeft: "10px",
									}}
									onClick={() => {
										const audio = new Audio(alarmAudio);
										audio.play().catch((err) => {
											logger(err);
										});
									}}
								/>
							</div>
						</div>
					</GradientBorder>
				</ListItem>
				<ListItem
					sx={{
						justifyContent: "center",
					}}
				>
					<GradientBorder
						gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
						width="1px"
						className={styles.setting_row}
					>
						<div>
							<div>Alert on new KOT</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<Switch
									checked={settingsContext.settings.newKotAlert}
									onChange={() => {
										settingsContext.updateSettings({
											...settingsContext.settings,
											newKotAlert: !settingsContext.settings.newKotAlert,
										});
									}}
									sx={{
										"& .MuiSwitch-switchBase.Mui-checked": {
											color: "#9E5E28",
										},
										"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
											backgroundColor: "#9E5E28",
										},
									}}
								/>
								<PlayCircle
									fontSize="large"
									sx={{
										marginLeft: "10px",
									}}
									onClick={() => {
										const audio = new Audio(kotAlert);
										audio.play().catch((err) => {
											logger(err);
										});
									}}
								/>
							</div>
						</div>
					</GradientBorder>
				</ListItem>
				<ListItem
					sx={{
						justifyContent: "center",
					}}
				>
					<GradientBorder
						gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
						width="1px"
						className={[styles.setting_row, styles.clickable].join(" ")}
					>
						<div
							onClick={async () => {
								vibrator.tap();
								await restaurantContext.refreshRestaurant();
								navigate("/kot-view");
							}}
						>
							Refresh Restaurant
						</div>
					</GradientBorder>
				</ListItem>
				<ListItem
					sx={{
						justifyContent: "center",
					}}
				>
					<button
						className={commonStyles.primary_button}
						onClick={() => {
							vibrator.tap();
							navigate("/kot-view");
						}}
					>
						Done
					</button>
				</ListItem>
			</List>
		</div>
	);
};

export default Settings;
