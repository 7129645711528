import axios from "axios";
import { createContext, useState, useContext } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";
import { notifications } from "./NotificationProvider";
import { settings } from "./SettingsProvider";
import kotAlert from "../assets/audio/kot_alert.aac";
import { kotContext } from "./KotProvider";

const orders = createContext();

const { Provider } = orders;

const OrderProvider = ({ children }) => {
	const notificationsContext = useContext(notifications);
	const settingsContext = useContext(settings);
	const {setKots} = useContext(kotContext)

	const [orders, setStateOrders] = useState(
		JSON.parse(sessionStorage.getItem("orders")) || {}
	);

	const setOrders = (orders) => {
		sessionStorage.setItem("orders", JSON.stringify(orders));
		setStateOrders(orders);
	};

	const addOrder = (order) => {
		setStateOrders((prev) => {
			let newOrders = { ...prev };
			newOrders[order.table._id] = order;
			sessionStorage.setItem("orders", JSON.stringify(newOrders));
			return newOrders;
		});
	};

	const refreshOrders = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/running`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setKots(res.data.kots)

			setOrders(
				res.data.orders.reduce((acc, order) => {
					acc[order._id] = order;
					return acc;
				}, {})
			);

			toast.success("Orders refreshed");
		} catch (err) {
			logger(err);
			toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                      autoClose: 2000
                  })
		}
	};

	const moveOrder = (from, to) => {
		const newOrders = { ...orders };

		const order = newOrders[from];

		delete newOrders[from];
		newOrders[to] = order;

		setOrders(newOrders);
	};

	const updateOrder = async (order) => {
		setStateOrders((prev) => {
			let newOrders = { ...prev };
			newOrders[order._id] = order;
			let shouldAlert = false;
			if (order.status === "cancelled") {
				delete newOrders[order._id];
				notificationsContext.addNotification({
					title: `Table - ${order.table.name}`,
					body: `Order has been cancelled`,
				});
			} else if (order.status === "settled") {
				delete newOrders[order._id];
				notificationsContext.addNotification({
					title: order.table
						? `Table - ${order.table.name}`
						: "Temporary Table",
					body: `Order has been settled`,
				});
			} else if (order.status === "completed") {
				// delete newOrders[order._id];
				notificationsContext.addNotification({
					title: `Table - ${order.table.name}`,
					body: `Order has been billed`,
				});
			} else if (!(order._id in prev) && order.status === "running") {
				notificationsContext.addNotification({
					title: `Table - ${order.table.name}`,
					body: `New order has started`,
				});
				shouldAlert = true;
			} else if (order.kots.length > prev[order._id].kots.length) {
				notificationsContext.addNotification({
					title: `Table - ${order.table.name}`,
					body: `New kot has been added`,
				});
				shouldAlert = true;
			} else if (order.kots.length < prev[order._id].kots.length) {
				notificationsContext.addNotification({
					title: `Table - ${order.table.name}`,
					body: `Kot has been removed`,
				});
			} else if (order.kots.length === prev[order._id].kots.length) {
				notificationsContext.addNotification({
					title: `Table - ${order.table.name}`,
					body: `Order has been updated`,
				});
			}

			if (shouldAlert && settingsContext.settings.newKotAlert) {
				const audio = new Audio(kotAlert);
				audio.play().catch((err) => {
					logger(err);
				});
			}

			sessionStorage.setItem("orders", JSON.stringify(newOrders));
			return newOrders;
		});
	};


	const clearOrders = () => {
		setOrders(null);
	};

	return (
		<Provider
			value={{
				orders,
				setOrders,
				addOrder,
				refreshOrders,
				moveOrder,
				clearOrders,
				updateOrder
			}}
		>
			{children}
		</Provider>
	);
};

export { OrderProvider, orders };
