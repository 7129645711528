import React, { useContext, useState } from "react";
import { restaurant } from "../../providers/RestaurantProvider";
import {
	Dialog,
	DialogTitle,
	Switch,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
} from "@mui/material";
import GradientBorder from "../GradientBorder/GradientBorder";
import styles from "./OutOfStockModal.module.css";
import commonStyles from "../../styles.module.css";
import axios from "axios";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import GradientSwitch from "../GradientSwitch/GradientSwitch";

const OutOfStockModal = ({ open, setOpen }) => {
	const restaurantContext = useContext(restaurant);

	const [outOfStockItems, setOutOfStockItems] = useState(
		Object.values(restaurantContext.items ?? {})
			.filter((item) => !item?.available)
			.map((i) => i._id)
	);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, rgb(158, 94, 40) 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<DialogTitle
						style={{
							backgroundColor: "#0002",
							backdropFilter: "blur(8px)",
							width: "100%",
							borderRadius: "25px 25px 0 0",
							padding: "0",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							zIndex: 5,
							padding: "12px 0",
							fontWeight: 400,
							fontSize: "24px",
						}}
					>
						<div>Mark Out of Stock</div>
						<div className={styles.search}>
							<input
								type="text"
								placeholder="Search"
								value={searchQuery}
								onChange={(e) => {
									setSearchQuery(e.target.value);
								}}
							/>
						</div>
					</DialogTitle>
					<div className={styles.items_table}>
						<Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
							<Tab label="Food" value={0} />
							<Tab label="Drinks" value={1} />
						</Tabs>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow
										sx={{
											"& .MuiTableCell-root": {
												fontWeight: 600,
												color: "#9E5E28",
												fontFamily: "Popins, sans-serif",
											},
										}}
									>
										<TableCell>Item</TableCell>
										<TableCell>Out of Stock</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.values(restaurantContext.items)
										.filter(
											(item) =>
												item.name
													.toLowerCase()
													.includes(searchQuery.toLowerCase()) &&
												((selectedTab === 0 && !item.isBar) ||
													(selectedTab === 1 && item.isBar))
										)
										.sort((a, b) => a.name.localeCompare(b.name))
										.map((item) => {
											return (
												<TableRow
													sx={{
														"& .MuiTableCell-root": {
															fontWeight: 400,
															color: "#9E5E28",
															fontFamily: "Popins, sans-serif",
														},
													}}
												>
													<TableCell>{item.name}</TableCell>
													<TableCell>
														<GradientSwitch
															gradient="linear-gradient(to right, #FF578C 0%, #E50149 100%)"
															checked={outOfStockItems.includes(item._id)}
															onChange={(value) => {
																if (value) {
																	setOutOfStockItems([
																		...outOfStockItems,
																		item._id,
																	]);
																} else {
																	setOutOfStockItems(
																		outOfStockItems.filter(
																			(i) => i !== item._id
																		)
																	);
																}
															}}
															sx={{
																"& .MuiSwitch-switchBase.Mui-checked": {
																	color: "#9E5E28",
																},
																"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
																	{
																		backgroundColor: "#9E5E28",
																	},
															}}
														/>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<div
						style={{
							backgroundColor: "#0002",
							backdropFilter: "blur(8px)",
							width: "100%",
							borderRadius: "0 0 25px 25px",
							padding: "0",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							zIndex: 5,
							padding: "12px 0",
							fontWeight: 400,
							fontSize: "24px",
						}}
					>
						<button
							className={commonStyles.primary_button}
							style={{
								fontSize: "16px",
							}}
							onClick={async () => {
								try {
									const res = await axios.put(
										`/restaurants/${localStorage.getItem(
											"restaurant"
										)}/items/availability`,
										{
											ids: outOfStockItems,
											unavailableReason: "Out of Stock",
										},
										{
											headers: {
												"x-auth-token": localStorage.getItem("token"),
											},
										}
									);

									logger(res.data);
									toast.success("Successfully updated!");
								} catch (err) {
									logger(err);
									toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                      autoClose: 2000
                  })
								} finally {
									setOpen(false);
								}
							}}
						>
							Confirm
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default OutOfStockModal;
