import React, { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { restaurant } from "../../providers/RestaurantProvider";
import { orders } from "../../providers/OrderProvider";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import { kotContext } from "../../providers/KotProvider";

let mySocket = null

const SocketWrapper = ({ children }) => {
	const restaurantContext = useContext(restaurant);
	const ordersContext = useContext(orders);
	const { updateKot } = useContext(kotContext);


	useEffect(() => {
		if (!restaurantContext.restaurant?._id) {
			return;
		}

		const socket = io(
			(
				process.env.NODE_ENV === "development"
					? "http://localhost:3000"
					: "https://shkspr24632.in"
			),
			{
				transportOptions: {
					polling: {
						extraHeaders: {
							'x-auth-token': localStorage.getItem("token"),
						},
					},
				}
			}
		);

		socket.on("connect", () => {
			logger("socket connected");
			const roomName = "kitchen" + restaurantContext.restaurant?._id;
			socket.emit('join room', roomName);
			socket.on('room status', (status) => {
				logger(status)
			});
		});

		mySocket = socket

		socket.on("disconnect", () => {
			logger("disconnected");
		});

		socket.on(`/order`, (data) => {
			if (localStorage.getItem("token")) ordersContext.updateOrder(data)
		});

		socket.on(`/item`, (data) => {
			if (localStorage.getItem("token"))
				restaurantContext.updateItem(data._id, data);
		});

		socket.on(`/waiter`, (data) => {
			if (localStorage.getItem("token"))
				toast.info(`Waiter has been called on Table ${data.table}`);
		});

		socket.on(`/table`, (data) => {
			restaurantContext.updateTable(data);
		});

		socket.on(`/table/remove`, (data) => {
			restaurantContext.removeTable(data._id);
		});
		socket.on(`/kot`, (data) => {
			logger("kotddat", data)
			toast.success("kot updated")
			updateKot(data)
		});


		return () => {
			socket.disconnect();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restaurantContext.restaurant?._id]);


	return <>{children}</>;
};

export { mySocket };
export default SocketWrapper;