import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import styles from "./PreparationRecipes.module.css";
import PreparationCard from "./PreparationCard";
import axios from "axios";
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import commonStyles from '../../styles.module.css'
import toast from "../../utils/toast";
import CurrentMenuDialog from "./CurrentMenu";
import CurrentMenuDownload from "./CurrentMenuDownload";
import html2canvas from "html2canvas";
import zIndex from "@mui/material/styles/zIndex";
import PreparationRecipeDownload from "./PreparationRecipeDownload";

const PreparationRecipes = () => {
    const [recipes, setRecipes] = useState([])
    const myRef= useRef()
    const recipeRef= useRef()
    const [additionalRecipes, setAdditionalRecipes] = useState([])
    const [properties, setProperties] = useState({})
    const fetchPreperationRecipes = async () => {
        try {
            const res = await axios.get(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/recipe/preparationRecipe/all`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            const propertiesObj = {}
            res.data?.forEach((recipeItem) => {
                recipeItem?.item?.properties?.forEach((prop) => {
                    propertiesObj[prop._id] = prop.name
                })
            })
            setProperties({ ...propertiesObj })
            setRecipes([...res.data])
        } catch (err) {
            console.log(err)
            if (err.isAxiosError && err.code === 'ERR_NETWORK') {
                toast.error("Network connection failed", {
                    autoClose: 2000
                })
            } else {
                toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                    autoClose: 2000
                })
            }
        }
    }
    const propertyWiseData = {}
    if (additionalRecipes[0]) {
        additionalRecipes.forEach(rec => {
            if (!propertyWiseData[rec.property]) {
                propertyWiseData[rec.property] = []
            }
            propertyWiseData[rec.property].push(rec)
        })
    }
    const [itemProperties, setItemProperties] = useState({})
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedRecipe, setSelectedRecipe] = useState({})
    const [showCurrentMenu, setShowCurrentMenu] = useState(false)

    const handleUpdateMenu = async () => {
        if (selectedItem?.properties) {
            const selectedItemProperties = selectedItem?.properties?.map((prop) => {
                return { ...prop, options: (itemProperties?.[prop._id] ?? []) }
            })
            try {
                const res = await axios.put(
                    `restaurants/${localStorage.getItem("restaurant")}/items/${selectedItem._id
                    }`,
                    { properties: selectedItemProperties },
                    {
                        headers: {
                            "x-auth-token": localStorage.getItem("token"),
                        },
                    }
                );
                setAdditionalRecipes({});
                setItemProperties({})
                fetchPreperationRecipes()
                toast.success("Successfully updated menu")
            } catch (err) {
                console.log(err)
                if (err.isAxiosError && err.code === 'ERR_NETWORK') {
                    toast.error("Network connection failed", {
                        autoClose: 2000
                    })
                } else {
                    toast.error(err?.response?.data?.message ?? "Something Went Wrong", {
                        autoClose: 2000
                    })
                }
            }

        }
    }

    useEffect(() => {
        fetchPreperationRecipes()
    }, [])

    useEffect(() => {
        const propertyWiseDataObj = {}
        Object.keys(propertyWiseData ?? {}).forEach(it => {
            propertyWiseDataObj[it] = [];
        })
        setItemProperties({
            ...propertyWiseDataObj,
            ...itemProperties
        })
    }, [])


    const downloadMenu=()=>{
        html2canvas(myRef.current).then(function(canvas) {
            const imageDataURL = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = imageDataURL;
            link.download = "converted-image.png";
            link.click();
        });
    }

    return (
        <div>
            <Header title="Preparation Recipes" />
            {
                additionalRecipes[0] &&
                <div className={styles.preparationRecipeHead}>
                    <button className={commonStyles.primary_button} onClick={() => { setAdditionalRecipes({}); setSelectedItem({}); setItemProperties({}) }}>
                        <ArrowCircleLeftRoundedIcon ></ArrowCircleLeftRoundedIcon>
                    </button>
                    <div style={{display:"flex"}}>
                        {
                            JSON.stringify(itemProperties) !== "{}" &&
                            <button className={commonStyles.primary_button} onClick={handleUpdateMenu}>Update Menu</button>
                        }
                        <button className={commonStyles.primary_button} onClick={()=>setShowCurrentMenu(true)}>View Current Menu</button>
                        <button className={commonStyles.primary_button} onClick={()=>downloadMenu()}>Download Menu</button>
                    </div>
                </div>
            }
            {
                additionalRecipes[0] ?
                    <>
                        {
                            (Object.keys(propertyWiseData ?? {})).map(propKey => {
                                return <div className={styles.propertyWiseList}>
                                    <div>
                                        <h3>{properties[propKey] ?  properties[propKey] : propKey}</h3>

                                    </div>
                                    <div className={styles.card_list}>
                                        {(propertyWiseData[propKey] ?? [])?.map((recipe, index) => {
                                            return <PreparationCard recipeRef={recipeRef} key={index} setItemProperties={setItemProperties} propKey={propKey} setSelectedRecipe={setSelectedRecipe} itemProperties={itemProperties} setAdditionalRecipes={setAdditionalRecipes} recipe={recipe} />
                                        })}
                                    </div>
                                </div>
                            })

                        }
                    </>
                    :
                    <div className={styles.card_list}>
                        {
                            (recipes ?? [])?.map((recipe, index) => {
                                return <PreparationCard recipeRef={recipeRef} key={index} setSelectedItem={setSelectedItem} setItemProperties={setItemProperties} setSelectedRecipe={setSelectedRecipe} itemProperties={itemProperties} setAdditionalRecipes={setAdditionalRecipes} recipe={recipe} />
                            })
                        }

                    </div>
            }
            <CurrentMenuDialog
                open={showCurrentMenu}
                setOpen={setShowCurrentMenu}
                selectedItem={selectedItem}
            />
            <div style={{opacity:"0%", zIndex:-1}} >
            <CurrentMenuDownload myRef={myRef} selectedItem={selectedItem}></CurrentMenuDownload>
            </div>
            <PreparationRecipeDownload recipeRef={recipeRef} recipe={selectedRecipe} setSelectedRecipe={setSelectedRecipe} ></PreparationRecipeDownload>
        </div>
    );
};

export default PreparationRecipes;
