import React from "react";
import styles from "./GradientText.module.css";

const GradientText = ({ gradient, text, style }) => {
	return (
		<div
			style={{
				...style,
				backgroundImage: `${gradient}`,
				display: "inline-block",
			}}
			className={styles.gradient_text}
		>
			{text}
		</div>
	);
};

export default GradientText;
