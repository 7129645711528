import React from "react";
import { Dialog, Typography } from "@mui/material";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./PreparationInput.module.css";

const CurrentMenuDialog = ({ open, setOpen, selectedItem }) => {
	const propertWiseItems={}
    selectedItem?.properties?.forEach(prop=>{
            propertWiseItems[prop._id]=prop
    })
	return (
		<Dialog
			open={open}
			onClose={(e) => {
				e.stopPropagation()
				setOpen(false)
			}}

			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="h6"
						component=""h6
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Current Menu
					</Typography>
					
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                        {
                            Object.values(propertWiseItems ?? {}).filter(prop=>prop.options[0]).map(prop=>{
                                return <div style={{display:'flex', flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"}}>
                                    <b style={{width:"100%", textAlign:"left"}}>{prop.name}</b>
                                    {prop.options.map(item=>{
                                        return <li>{item.name}</li>
                                    })}
                                </div>
                            })
                        }
                </div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default CurrentMenuDialog;
