import React from "react";

const GradientBorder = ({ gradient, children, className, width }) => {
	return (
		<div
			style={{
				background: `${gradient}`,
				padding: `${width}`,
			}}
			className={className}
		>
			{children}
		</div>
	);
};

export default GradientBorder;
